.team-carousel {
    width: 100%;
    padding: 6rem 1rem;
    background-color: #048433;
}

button.rec-dot{
    background-color: rgb(255, 255, 255);

  }

button.rec-dot_active, button.rec-dot:focus  {
    background-color:  #75b02a;
    box-shadow: inset 0 0 1px 1px #048433;
  }

  button.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px #048433;
}