.hero-img {
    background: rgba(0, 0, 0, 0);
    max-width: 100%;
    height: 300px;
    width: auto\9;
    position: relative;
}

.hero-img:before {
    content: '';
    background: url('../assets/heroImg.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
}

.hero-img .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1 {
    font-size: 2.4rem;
}

.hero-img p {
    font-size: 1.4rem;
}

@media screen and (max-width:1280px) and (max-height: 191px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}