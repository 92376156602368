.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.about-page{
  background-color: #f6f6f6;
  color: #333;
  min-height: 150vh;
}
.about-page p{
  color: black;
}

.about-page h1{
  color: black;
}

.about-heading {
  margin-top: 20px; 
}

.about-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 900px; 
  margin-top: 2%;
}
.text-content h2{
  color: black;

}

.text-content {
  flex: 1;
  margin-right: 20px;
}

.image-caption-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px; 
}

.about-image {
  max-width: 400px; 
}

.image-caption {
  font-style: italic;
  color: #777;
}

.vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vision-container h2{
  color: black;
  text-align: right;
}

.vision-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: black;
  max-width: 900px; 
}

.vision-image {
  max-width: 400px; 
  order: 1; 
  margin-right: 20px; 
}

.vision-text-content {
  flex: 1;
  order: 2; 
}

.vision-video {
  max-width: 400px; 
  order: 1; 
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.video-caption {
  font-style: italic;
  color: #777;
}


@media (max-width: 768px) {
  .about-content,
  .vision-content {
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-height: 138vh;
  }

  .image-caption-container,
  .video-caption {
    align-items: center;
    margin-left: 0;
    margin-top: 10px; /* Add some space between the text and the image on mobile */
    text-align: center; /* Center align the image caption on mobile */
  }

  .vision-text-content, 
  .text-content {
   width: 90%;
    margin-top: 10px; /* Add some space between the video and the text on mobile */
    text-align: left; /* Align the vision text content to the left on mobile */
  }

  .about-image,
  .vision-image,
  .vision-video {
    margin-right: 0; /* Remove margin-right on images and video on mobile */
    max-width: 100%; /* Set the image and video width to 100% on mobile */
  }
}