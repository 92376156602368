.floating-chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-chat-button {
  position: sticky;
  bottom: 0;
  background-color: rgb(255, 255, 255);
}

.bot-message {
  background-color: #f2f2f2;
  color: black;
}

.user-message {
  background-color: #0084ff;
  color: white;
}