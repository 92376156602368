.hero-img-pricing {
    background: rgba(0, 0, 0, 0);
    height: 300px;
    width: auto;
    position: relative;
}

.hero-img-pricing:before {
    content: '';
    background: url('../assets/showcase.gif') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
}

.hero-img-pricing .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img-pricing h1 {
    font-size: 2.4rem;
}

.hero-img-pricing p {
    font-size: 1.4rem;
}

@media screen and (max-width:1280px) and (max-height: 191px) {
    .hero-img-pricing h1 {
        font-size: 2rem;
    }
}