.react-chatbot-kit-chat-container {
    width: 400px;
}


.react-chatbot-kit-user-chat-message {
    width: 200px
}

.react-chatbot-kit-chat-bot-message {
    width: 300px;
    max-width: 500px
}

.react-chatbot-kit-chat-bot-message span {
    width: 100%;
    max-width: 500px
}

@media (max-width: 600px) {
    .react-chatbot-kit-chat-container {
        width: 100%;
        max-width: 600px;
    }

    .react-chatbot-kit-user-chat-message {
        width: 100%;

        .react-chatbot-kit-chat-bot-message {
            width: 100%;
            max-width: 100%;
        }

        .react-chatbot-kit-chat-bot-message span {
            width: 100%;
            max-width: 100%;
        }
    }
}